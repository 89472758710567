import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";

import VueSplide from "@splidejs/vue-splide";
import ReadMore from 'vue-read-more';

import "@/assets/style.css";

Vue.use(ReadMore);


import customConfig from "@/config";

Vue.config.productionTip = false;
Vue.prototype.$customConfig = customConfig;
Vue.prototype.$window = window;

Vue.use(VueSplide);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
